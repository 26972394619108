import _ from 'lodash';
import Err from 'models/err';
import jsRsaSign from 'jsrsasign';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import qconsole from 'scripts/lib/qconsole';
import { v4 } from 'uuid';

import AgentService from 'scripts/infrastructure/backends/fake_backend_http/agent_service';
import AgentNotificationService from './fake_backend_http/agent_notification_service';
import AgentProfileService from './fake_backend_http/agent_profile_service';
import AgentReadService from './fake_backend_http/agent_read_service';
import AgentRoutingPreferencesService from 'scripts/infrastructure/backends/fake_backend_http/agent_routing_preferences_service';
import AgentStatusService from 'scripts/infrastructure/backends/fake_backend_http/agent_status_service';
import AgentTaskReadService from './fake_backend_http/agent_task_read_service';
import AIConversationSummaryService from './fake_backend_http/ai_conversation_summary_service';
import AITextCompletionService from './fake_backend_http/ai_text_completion_service';
import AISuggestedReplyService from './fake_backend_http/ai_suggested_reply_service';
import AnswerPerformanceService from './fake_backend_http/answer_performance_service';
import AudiencesService from './fake_backend_http/audiences_service';
import AvailableLanguageService from './fake_backend_http/available_language_service';
import ExternalCustomerLookupService from './fake_backend/external_customer_lookup_service';
import ExternalCustomerLookupActionsService from './fake_backend_http/external_customer_lookup_actions_service';
import ComplianceService from './fake_backend/compliance_service';
import CompositionService from './fake_backend_http/composition_service';
import ConversationCountService from './fake_backend/conversation_count_service';
import ConversationHistoryService from './fake_backend_http/conversation_history_service';
import ConversationWorkflowConfigService from './fake_backend/conversation_workflow_config_service';
import CustomAttributeService from './fake_backend_http/custom_attribute_service';
import CustomChannelsService from './fake_backend_http/custom_channels_service';
import CustomerExtensionService from './fake_backend/customer_extension_service';
import CustomerProfileService from './fake_backend_http/customer_profile_service';
import CustomerNoteService from './fake_backend_http/customer_note_service';
import EmailSuggestionsService from './fake_backend_http/email_suggestions_service';
import EmbeddedDatasetService from './fake_backend_http/embedded_dataset_service';
import EmbeddedReportService from './fake_backend/embedded_report_service';
import ExternalDataObjectService from './fake_backend_http/external_data_service';
import GreetingSuggestionsService from './fake_backend_http/greeting_suggestions_service';
import InboxService from './fake_backend_http/inbox_service';
import IntegrationsService from './fake_backend/integrations_service';
import ItemIdsService from './fake_backend_http/item_ids_service';
import IvrsService from './fake_backend_http/ivrs_service';
import LiveboardService from './fake_backend/liveboard_service';
import LanguagesService from './fake_backend_http/languages_service';
import MessagingConfigurationService from './fake_backend_http/messaging_configuration_service';
import PhoneControlsService from './fake_backend_http/phone_controls_service';
import PhraseSuggestionService from './fake_backend_http/phrase_suggestion_service';
import RelationshipLabelService from './fake_backend_http/relationship_label_service';
import RelationshipService from './fake_backend_http/relationship_service';
import ReportBuilderConfigService from './fake_backend_http/report_builder_config_service';
import ReportConfigsService from './fake_backend_http/report_configs_service';
import ReportService from './fake_backend/report_service';
import RoutingGroupService from './fake_backend_http/routing_group_service';
import RoutingQueueItemService from './fake_backend_http/routing_queue_item_service';
import ScheduledReportService from './fake_backend_http/scheduled_report_service';
import SearchService from './fake_backend_http/search_service';
import SharedReportConfigsService from './fake_backend_http/shared_report_configs_service';
import SnippetRevisionService from './fake_backend_http/snippet_revision_service';
import SnippetService from './fake_backend_http/snippet_service';
import SnippetSearchService from './fake_backend_http/snippet_search_service';
import StationConfigurationService from './fake_backend_http/station_configuration_service';
import TaskCommentService from './fake_backend_http/task_comment_service';
import TaskFollowerService from './fake_backend_http/task_follower_service';
import TeamService from './fake_backend_http/team_service';
import TopicHierarchyService from './fake_backend/topic_hierarchy_service';
import TopicService from './fake_backend_http/topic_service';
import TopicSuggestionsService from './fake_backend/topic_suggestions_service';
import TopicPattern from '../topic_pattern';
import TwilioTokenService from './fake_backend_http/twilio_token_service';
import VoiceConfigurationService from './fake_backend_http/voice_configuration_service';
import WidgetConfigurationService from './fake_backend_http/widget_configuration_service';

const MAX_SERVICE_LATENCY = 100;

const ORGID_ENTITY_PATTERN = '/orgs/(.+?)/(.+)?$';
const TOPIC_SUGGESTIONS_PATTERN = 'customers/(.+?)/conversations/(.+?)/topic-suggestions';
const RELATIONSHIP_PATTERN = 'customers/(.+?)/relationships/(.+?)';

export function createLocalRestBackend(datasets, s3, pubsub, getDatabase, incomingCallService) {
  let simulatedBackend = new SimulatedBackend(datasets, pubsub);

  _.bindAll(simulatedBackend, [
    'authenticate',
    'logout',
    'orgBasedHandler',
    'orgBasedHandlerV2',
    'externalCustomerLookup',
    'requestPasswordReset',
    'refreshToken',
  ]);

  const agentService = new AgentService(getDatabase, pubsub);
  const agentNotificationService = new AgentNotificationService(getDatabase, pubsub);
  const agentProfileService = new AgentProfileService(getDatabase, pubsub);
  const agentRoutingPreferencesService = new AgentRoutingPreferencesService(getDatabase, pubsub);
  const agentReadService = new AgentReadService(getDatabase, pubsub);
  const agentStatusService = new AgentStatusService(getDatabase, pubsub);
  const agentTaskReadService = new AgentTaskReadService(getDatabase, pubsub);
  const aiConversationSummaryService = new AIConversationSummaryService(getDatabase, pubsub);
  const aiTextCompletionService = new AITextCompletionService(getDatabase, pubsub);
  const aiSuggestedReplyService = new AISuggestedReplyService(getDatabase, pubsub);
  const answerPerformanceService = new AnswerPerformanceService(getDatabase);
  const audiencesService = new AudiencesService(getDatabase, pubsub);
  const availableLanguageService = new AvailableLanguageService(getDatabase, pubsub);
  const complianceService = new ComplianceService(pubsub, getDatabase);
  const compositionService = new CompositionService(getDatabase, pubsub);
  const conversationCountService = new ConversationCountService();
  const conversationHistoryService = new ConversationHistoryService(getDatabase, pubsub);
  const conversationWorkflowConfigService = new ConversationWorkflowConfigService(getDatabase);
  const customAttributeService = new CustomAttributeService(getDatabase);
  const customChannelsService = new CustomChannelsService(getDatabase, pubsub);
  const customerExtensionService = new CustomerExtensionService(pubsub, getDatabase);
  const customerProfileService = new CustomerProfileService(getDatabase, pubsub);
  const customerNoteService = new CustomerNoteService(getDatabase, pubsub);
  const emailSuggestionsService = new EmailSuggestionsService(getDatabase);
  const embeddedDatasetService = new EmbeddedDatasetService(getDatabase);
  const embeddedReportService = new EmbeddedReportService(getDatabase);
  const externalCustomerLookupService = new ExternalCustomerLookupService(getDatabase);
  const externalCustomerLookupActionsService = new ExternalCustomerLookupActionsService(getDatabase, pubsub);
  const externalDataObjectService = new ExternalDataObjectService(getDatabase, pubsub);
  const greetingSuggestionsService = new GreetingSuggestionsService(getDatabase);
  const inboxService = new InboxService(getDatabase, pubsub);
  const integrationsService = new IntegrationsService(getDatabase);
  const itemIdsService = new ItemIdsService(getDatabase, pubsub);
  const ivrsService = new IvrsService(getDatabase, pubsub);
  const liveboardService = new LiveboardService(null, getDatabase);
  const languagesService = new LanguagesService(getDatabase, pubsub);
  const messagingConfigurationService = new MessagingConfigurationService(getDatabase, pubsub);
  const phoneControlsService = new PhoneControlsService(getDatabase, pubsub, incomingCallService);
  const phraseSuggestionService = new PhraseSuggestionService(getDatabase, pubsub);
  const relationshipLabelService = new RelationshipLabelService(getDatabase, pubsub);
  const relationshipService = new RelationshipService(getDatabase, pubsub);
  const reportBuilderConfigService = new ReportBuilderConfigService();
  const reportConfigsService = new ReportConfigsService();
  const reportService = new ReportService(getDatabase);
  const routingGroupService = new RoutingGroupService(getDatabase, pubsub);
  const routingQueueItemService = new RoutingQueueItemService(getDatabase, pubsub);
  const scheduledReportService = new ScheduledReportService(getDatabase, pubsub);
  const searchService = new SearchService(getDatabase, pubsub);
  const sharedReportConfigsService = new SharedReportConfigsService(getDatabase);
  const snippetRevisionService = new SnippetRevisionService(getDatabase, pubsub);
  const snippetService = new SnippetService(getDatabase, pubsub);
  const snippetSearchService = new SnippetSearchService(getDatabase, pubsub);
  const stationConfigurationService = new StationConfigurationService(getDatabase, pubsub);
  const taskCommentService = new TaskCommentService(getDatabase, pubsub);
  const taskFollowerService = new TaskFollowerService(getDatabase, pubsub);
  const teamService = new TeamService(getDatabase, pubsub);
  const topicHierarchyService = new TopicHierarchyService(null, getDatabase);
  const topicService = new TopicService(getDatabase, pubsub);
  const twilioTokenService = new TwilioTokenService(getDatabase, pubsub);
  const voiceConfigurationService = new VoiceConfigurationService(getDatabase, pubsub);
  const widgetService = new WidgetConfigurationService(getDatabase, pubsub);

  return new LocalRestBackend({
    ...agentService.getRoutes(),
    ...agentNotificationService.getRoutes(),
    ...agentProfileService.getRoutes(),
    ...agentReadService.getRoutes(),
    ...agentRoutingPreferencesService.getRoutes(),
    ...agentStatusService.getRoutes(),
    ...agentTaskReadService.getRoutes(),
    ...aiConversationSummaryService.getRoutes(),
    ...aiTextCompletionService.getRoutes(),
    ...aiSuggestedReplyService.getRoutes(),
    ...answerPerformanceService.getRoutes(),
    ...audiencesService.getRoutes(),
    ...availableLanguageService.getRoutes(),
    ...complianceService.getRoutes(),
    ...compositionService.getRoutes(),
    ...conversationCountService.getRoutes(),
    ...conversationHistoryService.getRoutes(),
    ...conversationWorkflowConfigService.getRoutes(),
    ...customAttributeService.getRoutes(),
    ...customChannelsService.getRoutes(),
    ...customerExtensionService.getRoutes(),
    ...customerProfileService.getRoutes(),
    ...customerNoteService.getRoutes(),
    ...emailSuggestionsService.getRoutes(),
    ...embeddedDatasetService.getRoutes(),
    ...embeddedReportService.getRoutes(),
    ...externalCustomerLookupService.getRoutes(),
    ...externalCustomerLookupActionsService.getRoutes(),
    ...externalDataObjectService.getRoutes(),
    ...greetingSuggestionsService.getRoutes(),
    ...inboxService.getRoutes(),
    ...integrationsService.getRoutes(),
    ...itemIdsService.getRoutes(),
    ...ivrsService.getRoutes(),
    ...languagesService.getRoutes(),
    ...liveboardService.getRoutes(),
    ...messagingConfigurationService.getRoutes(),
    ...phoneControlsService.getRoutes(),
    ...phraseSuggestionService.getRoutes(),
    ...relationshipLabelService.getRoutes(),
    ...relationshipService.getRoutes(),
    ...reportConfigsService.getRoutes(),
    ...reportBuilderConfigService.getRoutes(),
    ...reportService.getRoutes(),
    ...routingGroupService.getRoutes(),
    ...routingQueueItemService.getRoutes(),
    ...scheduledReportService.getRoutes(),
    ...searchService.getRoutes(),
    ...sharedReportConfigsService.getRoutes(),
    ...snippetRevisionService.getRoutes(),
    ...snippetService.getRoutes(),
    ...snippetSearchService.getRoutes(),
    ...stationConfigurationService.getRoutes(),
    ...taskCommentService.getRoutes(),
    ...taskFollowerService.getRoutes(),
    ...teamService.getRoutes(),
    ...topicHierarchyService.getRoutes(),
    ...topicService.getRoutes(),
    ...twilioTokenService.getRoutes(),
    ...voiceConfigurationService.getRoutes(),
    ...widgetService.getRoutes(),
    '/api/v3/desktop_tokens/:id': {
      DELETE: simulatedBackend.logout,
    },
    '/api/v3/desktop_tokens': simulatedBackend.authenticate,
    '/api/v1/password_reset_tokens': simulatedBackend.requestPasswordReset,
    '/api/v3/refresh_tokens': simulatedBackend.refreshToken,
    '/api/customreporting': simulatedBackend.customReporting,
    '/api/v1/orgs': simulatedBackend.orgBasedHandler,
    '/api/v2/orgs': simulatedBackend.orgBasedHandlerV2,
    '/api/v1/system-configuration': simulatedBackend.systemConfiguration,
    'https://bucket.s3.amazonaws.com': s3.post.bind(s3),
  });
}

class SimulatedBackend {
  constructor(datasets, pubsub) {
    this.datasets = datasets;
    this.pubsub = pubsub;
    this.authCache = new AuthCache(window.sessionStorage);
  }

  customReporting(attrs, onReceive) {
    let path = window.location.pathname;
    let delay = /slow/.test(path) ? 2000 : 0;
    let status = /error/.test(path) ? 500 : 200;

    setTimeout(() => {
      onReceive(null, {
        status,
        statusText: statusText(status),
        response: {
          dirs: ['dir1/', 'dir2/', 'slow/', 'error/'],
          files: [{ name: 'file1' }, { name: 'file2' }],
        },
      });
    }, delay);
  }

  orgBasedHandler(attrs, onReceive, path, action) {
    const pathParts = path.match(ORGID_ENTITY_PATTERN);

    if (!pathParts) {
      return onReceive(null, { status: 404, statusText: statusText(404) });
    }

    const orgId = pathParts[1];
    const restOfPath = pathParts[2];

    if (restOfPath === 'external-customer-lookup') {
      return this.externalCustomerLookup(orgId, attrs, onReceive);
    } else if (restOfPath.startsWith('customer-extensions')) {
      return this.customerExtensions(orgId, attrs, onReceive);
    } else if (restOfPath.startsWith('configuration/customer-profile-def')) {
      return this.configureCustomerProfileDef(orgId, attrs, onReceive);
    } else if (restOfPath.endsWith('topic-suggestions')) {
      let topicSuggestionsParts = path.match(TOPIC_SUGGESTIONS_PATTERN);
      return this.topicSuggestions(orgId, topicSuggestionsParts[1], topicSuggestionsParts[2], onReceive);
    } else if (restOfPath.endsWith('feature-set')) {
      return this.featureSet(orgId, onReceive);
    } else if (restOfPath.endsWith('agent-status-reasons')) {
      return this.agentStatusReasonsHandler(orgId, attrs, onReceive);
    } else if (restOfPath.endsWith('kb-variables')) {
      return this.kbVariablesHandlers(action, orgId, attrs, onReceive);
    } else if (restOfPath.endsWith('channel-configuration')) {
      return this.channelConfigurationHandlers(action, orgId, attrs, onReceive);
    } else if (restOfPath.endsWith('communication-queues')) {
      return this.communicationQueueHandlers(action, orgId, attrs, onReceive);
    } else if (restOfPath.includes('relationship-labels')) {
      return this.relationshipLabelsHandler(orgId, attrs, onReceive);
    } else if (restOfPath.includes('relationships')) {
      let relationshipParts = path.match(RELATIONSHIP_PATTERN);
      return this.relationshipHandler(orgId, relationshipParts[1], attrs, onReceive);
    } else if (restOfPath.includes('configuration/endpoints')) {
      return this.endpointHandlers(action, orgId, attrs, onReceive);
    }

    return onReceive(null, { status: 404, statusText: statusText(404) });
  }

  orgBasedHandlerV2(attrs, onReceive, path, action) {
    const pathParts = path.match(ORGID_ENTITY_PATTERN);

    if (!pathParts) {
      return onReceive(null, { status: 404, statusText: statusText(404) });
    }

    const orgId = pathParts[1];
    const restOfPath = pathParts[2];

    if (restOfPath.includes('channel-preferences')) {
      return this.channelPreferencesHandlers(action, orgId, attrs, onReceive);
    }

    return onReceive(null, { status: 404, statusText: statusText(404) });
  }

  kbVariablesHandlers(action, orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      switch (action) {
        case 'GET':
          return onReceive(null, {
            status: 200,
            statusText: statusText(200),
            response: dataset.kbVariables,
          });

        default:
          return undefined;
      }
    });
  }

  communicationQueueHandlers(action, orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      switch (action) {
        case 'GET':
          return onReceive(null, {
            status: 200,
            statusText: statusText(200),
            response: dataset.communicationQueues,
          });

        default:
          return undefined;
      }
    });
  }

  channelPreferencesHandlers(action, orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, () => {
      switch (action) {
        case 'GET':
          return onReceive(null, {
            status: 200,
            statusText: statusText(200),
            response: { preferences: { MESSAGING: false, NON_INTERACTIVE: false, VOICE: false }, version: 1 },
          });

        default:
          return undefined;
      }
    });
  }

  channelConfigurationHandlers(action, orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      switch (action) {
        case 'GET':
          return onReceive(null, {
            status: 200,
            statusText: statusText(200),
            response: dataset.channelConfiguration,
          });

        default:
          return undefined;
      }
    });
  }

  endpointHandlers(action, orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      switch (action) {
        case 'GET':
          return onReceive(null, {
            status: 200,
            statusText: statusText(200),
            response: dataset.channelConfiguration.endpoints,
          });

        default:
          return undefined;
      }
    });
  }

  agentStatusReasonsHandler(orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      onReceive(null, {
        status: 200,
        statusText: statusText(200),
        response: dataset.agentStatusReasons,
      });
    });
  }

  customerExtensions(orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      CustomerExtensionService.update(dataset, this.pubsub, orgId, attrs);
      onReceive(null, {
        status: 200,
        statusText: statusText(200),
      });
    });
  }

  configureCustomerProfileDef(orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      onReceive(null, {
        status: 200,
        statusText: statusText(200),
        response: dataset.customerProfileDef,
      });
    });
  }

  externalCustomerLookup(orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      const results = ExternalCustomerLookupService.findProfiles(dataset, orgId, attrs);
      onReceive(null, {
        status: 200,
        statusText: statusText(200),
        response: results,
      });
    });
  }

  relationshipHandler(orgId, customerId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      let relationships = _.get(_.find(dataset.customers, { id: customerId }), 'relationships');

      onReceive(null, {
        status: 200,
        statusText: statusText(200),
        response: relationships,
      });
    });
  }

  relationshipLabelsHandler(orgId, attrs, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      onReceive(null, {
        status: 200,
        statusText: statusText(200),
        response: dataset.relationshipLabels,
      });
    });
  }

  topicSuggestions(orgId, customerId, conversationId, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      const results = TopicSuggestionsService.find(dataset, customerId, conversationId);
      onReceive(null, {
        status: 200,
        statusText: statusText(200),
        response: results,
      });
    });
  }

  featureSet(orgId, onReceive) {
    this.datasets.findByOrgId(orgId, (err, dataset) => {
      onReceive(null, {
        status: 200,
        statusText: statusText(200),
        response: dataset.featureSet || {},
      });
    });
  }

  systemConfiguration(attrs, onReceive) {
    onReceive(null, { status: 200, statusText: statusText(200), response: {} });
  }

  authenticate(attrs, onReceive) {
    let onFail = (errors, status = 401) => {
      logErrors(errors);
      onReceive(null, {
        status,
        statusText: statusText(status),
        response: status === 401 ? 'Not authorized' : { errors },
      });
    };

    let onSuccess = (orgId, userId, responseData) => {
      this.datasets.findByOrgId(orgId, (err, dataset) => {
        // remember auth for token refresh because it's difficult to simulate httponly cookie in the local backend
        this.authCache.set(responseData);

        // set currentAgent for comm simulation
        dataset.currentAgent = _.find(dataset.agents, { id: userId });

        onReceive(null, { status: 200, statusText: statusText(200), response: responseData });
      });
    };

    if (attrs.token) {
      this._jwTokenAuth({
        jwToken: attrs.token,
        onFail,
        onSuccess,
      });
    } else {
      this._passwordAuth({
        username: attrs.username,
        password: attrs.password,
        onFail,
        onSuccess,
      });
    }
  }

  logout(attrs, onReceive) {
    this.authCache.remove();
    onReceive(null, { status: 200, statusText: statusText(200) });
  }

  // always return HTTP status 202 to avoid giving malicious actors any information
  // but output the failure message to console.warn for developers (fakeBackend only)
  requestPasswordReset(attrs, onReceive) {
    this._findOrgAndUserByUsername(attrs.username, (err, { userDto, orgId } = {}) => {
      if (err) {
        logErrors(err.errors);
        return onReceive(null, { status: 202, statusText: statusText(202) });
      }

      _createResetPasswordToken(userDto);

      // output message to console simulating what would have been sent in email
      simulateEmailToConsole({
        actionMessage: 'Reset password',
        jwToken: createFakeJwt({
          passwordResetToken: userDto.passwordResetToken,
          orgId,
        }),
        path: 'reset-password',
      });

      _setResetPasswordTokenSentAt(userDto);

      onReceive(null, { status: 202, statusText: statusText(202) });
    });
  }

  refreshToken(attrs, onReceive) {
    let onFail = (errors, status = 401) => {
      logErrors(errors);
      onReceive(null, {
        status,
        statusText: statusText(status),
        response: status === 401 ? 'Not authorized' : { errors },
      });
    };

    let onSuccess = (orgId, userId, responseData) => {
      onReceive(null, { status: 200, statusText: statusText(200), response: responseData });
    };

    let cachedAuth = this.authCache.get();

    this._jwTokenAuth({
      jwToken:
        attrs.token ||
        (cachedAuth &&
          jsRsaSign.jws.JWS.sign(
            'HS256',
            JSON.stringify({ alg: 'HS256', typ: 'JWT' }),
            JSON.stringify(cachedAuth),
            'key'
          )),
      onFail,
      onSuccess,
    });
  }

  _passwordAuth({ username, password, onFail, onSuccess }) {
    if (!password) {
      return onFail([{ attr: 'password', code: Err.Code.BLANK, detail: 'password cannot be blank' }], 400);
    }

    this._findOrgAndUserByUsername(username, (err, { orgId, userDto } = {}) => {
      if (err) {
        if (err.httpStatus === 401) {
          return onFail('Unauthorized', 401);
        }
        return onFail(err.errors, err.httpStatus);
      }

      // fakeBackend doesn't check passwords, but it would happen here
      // if userDto.password === password

      return onSuccess(orgId, userDto.id, _createClaims({ orgId }, userDto));
    });
  }

  _jwTokenAuth({ jwToken, onFail, onSuccess }) {
    this._findOrgAndUserByToken(jwToken, (err, { orgId, userDto } = {}) => {
      if (err) {
        return onFail(err.errors, err.httpStatus);
      }

      let claims = { orgId };
      if (userDto.passwordResetToken) {
        claims.passwordReset = true;
      }
      if (userDto.activationToken) {
        claims.userActivation = true;
      }

      _clearOneTimeToken(userDto, jwToken);

      return onSuccess(orgId, userDto.id, _createClaims(claims, userDto));
    });
  }

  _findOrgAndUserByToken(jwToken, cb) {
    let claims;
    try {
      claims = jwtDecode(jwToken);
    } catch (e) {
      return cb({
        httpStatus: 401,
        errors: [{ attr: 'token', code: Err.Code.INVALID, detail: 'Invalid token' }],
      });
    }

    if (!claims.orgId) {
      return cb({
        httpStatus: 401,
        errors: _createSingleError('orgId', 'Missing orgId in claims', Err.Code.BLANK),
      });
    }

    this.datasets.findByOrgId(claims.orgId, (err, dataset) => {
      if (err) {
        return cb({
          httpStatus: 401,
          errors: [{ code: Err.Code.NOT_EXIST, detail: `Cannot find database for orgId: ${claims.orgId}` }],
        });
      }

      let findUser = predicateObject => _.find(dataset.users, predicateObject);

      let userDto;
      if (claims.activationToken) {
        userDto = findUser({ activationToken: claims.activationToken });
        if (!userDto) {
          return cb({
            httpStatus: 401,
            errors: [{ code: Err.Code.NOT_EXIST, detail: `activationToken ${claims.activationToken} not found` }],
          });
        }
        let isExpiredToken = moment(userDto.activationSentAt)
          .add(24, 'hours')
          .isBefore(moment());
        if (isExpiredToken) {
          return cb({
            httpStatus: 401,
            errors: _createSingleError(
              'token',
              `activationToken ${claims.activationToken} is expired`,
              Err.Code.INVALID
            ),
          });
        }
      } else if (claims.passwordResetToken) {
        userDto = findUser({ passwordResetToken: claims.passwordResetToken });
        if (!userDto) {
          return cb({
            httpStatus: 401,
            errors: [{ code: Err.Code.NOT_EXIST, detail: `passwordResetToken ${claims.passwordResetToken} not found` }],
          });
        }
      } else {
        userDto = findUser({ id: claims.userId });
        if (!userDto) {
          return cb({
            httpStatus: 401,
            errors: [{ code: Err.Code.NOT_EXIST, detail: `user ${claims.userId} not found` }],
          });
        }
      }

      return cb(null, { orgId: claims.orgId, userDto });
    });
  }

  _findOrgAndUserByUsername(username, cb) {
    if (!username) {
      return cb({
        httpStatus: 400,
        errors: _createSingleError('username', 'username cannot be blank', Err.Code.BLANK),
      });
    }

    let match = username.match(/@(.*)/);
    let loginDomain = match && match[1];
    if (!loginDomain) {
      return cb({
        httpStatus: 401,
        errors: _createSingleError('username', `Email "${username}" does not have domain`, Err.Code.INVALID),
      });
    }

    this.datasets.findByCompanyDomain(loginDomain, (err, dataset) => {
      if (err) {
        return cb({
          httpStatus: 401,
          errors: [{ code: Err.Code.NOT_EXIST, detail: `Cannot find database for domain: ${loginDomain}` }],
        });
      }

      let userDto = _.find(dataset.users, { username });
      if (!userDto) {
        return cb({
          httpStatus: 401,
          errors: [
            {
              code: Err.Code.NOT_EXIST,
              detail: `User with email ${username} not found in database ${dataset.orgId}`,
            },
          ],
        });
      }

      if (userDto.disabledAt) {
        return cb({
          httpStatus: 401,
          errors: [{ code: Err.Code.NOT_EXIST, detail: 'Your account has been disabled' }],
        });
      }

      return cb(null, { orgId: dataset.orgId, userDto });
    });
  }
}

function statusText(status) {
  switch (status) {
    case 200:
      return 'OK';
    case 202:
      return 'Accepted';
    case 400:
      return 'Bad Request';
    case 401:
      return 'Unauthorized';
    case 404:
      return 'Not Found';
    case 500:
      return 'Internal Server Error';

    default:
      return `Status code ${status}`;
  }
}

class LocalRestBackend {
  constructor(handlers) {
    this.asyncEnabled = true;
    this._inProgress = 0;
    this.handlers = handlers;
  }

  axios() {
    if (!this.localAxios) {
      this.localAxios = new LocalRestBackendAxios(this.handlers);
    }
    return this.localAxios;
  }

  delete(path, onReceive) {
    return this.handleRequest('DELETE', path, {}, onReceive);
  }

  get(path, onReceive) {
    return this.handleRequest('GET', path, {}, onReceive);
  }

  post(path, attrs, onReceive) {
    return this.handleRequest('POST', path, attrs, onReceive);
  }

  put(path, attrs, onReceive) {
    return this.handleRequest('PUT', path, attrs, onReceive);
  }

  patch(path, attrs, onReceive) {
    return this.handleRequest('PATCH', path, attrs, onReceive);
  }

  postUpload(path, attrs, listeners) {
    let restHandler = this._getHandler(path);

    this._simulateServiceLatency(() => {
      if (restHandler) {
        restHandler(path, attrs, xhr => listeners.load({ target: xhr }));
      } else {
        listeners.error({ target: { status: 404, statusText: statusText(404) } });
      }
    });
  }

  handleRequest(type, path, attrs, onReceive) {
    let restHandler = this._getHandler(path, type);

    let aborted = false;
    this._simulateServiceLatency(done => {
      if (aborted) {
        return;
      }
      const callback = function() {
        if (onReceive != null) {
          onReceive.apply(null, arguments);
        }
        done();
      };

      if (restHandler) {
        restHandler(attrs, callback, path, type);
      } else {
        callback(null, { status: 404, statusText: statusText(404) });
      }
    });

    return {
      abort() {
        aborted = true;
      },
    };
  }

  get inProgress() {
    return this._inProgress;
  }

  _simulateServiceLatency(responseFn) {
    if (this.asyncEnabled) {
      this._inProgress++;
      setTimeout(() => responseFn(() => this._inProgress--), _.random(MAX_SERVICE_LATENCY));
    } else {
      responseFn(_.noop);
    }
  }

  _getHandler(path, action) {
    const matchingHandler = _.find(this.handlers, (handler, route) => {
      const pattern = new TopicPattern(route);
      return pattern.doesMatch(path);
    });

    if (matchingHandler && matchingHandler[action]) {
      return matchingHandler[action];
    }

    let matchingUrl = _.keys(this.handlers).find(key => path.startsWith(key));
    return matchingUrl ? this.handlers[matchingUrl] : null;
  }
}

export class LocalRestBackendAxios extends LocalRestBackend {
  handleRequest(type, path, attrs) {
    return new Promise((resolve, reject) => {
      super.handleRequest(type, path, attrs, (err, res) => {
        if (err != null) {
          reject(err);
          return;
        }
        res.data = res.response;
        resolve(res);
      });
    });
  }
}

// simulate sending an email by console.logging a link via window.navigate
export function simulateEmailToConsole({ actionMessage, jwToken, path }) {
  let url = [window.location.origin, 'user', path].join('/');
  let pushStateUrlCommand = `window.router.navigateToUrl("${url}?token=${jwToken}")`;

  let simulatedEmailMessage = `${actionMessage} with this command: ${pushStateUrlCommand}`;

  qconsole.log(simulatedEmailMessage);
}

export function createFakeJwt(claims) {
  let jwtPayload = Buffer.from(JSON.stringify(claims)).toString('base64');
  return `header.${jwtPayload}.signature`;
}

function logErrors(errors) {
  _.forEach(errors, e => qconsole.warn(e.detail));
}

function _createSingleError(attr, detail, code) {
  return [{ attr, detail, code }];
}

function _createResetPasswordToken(userDto) {
  userDto.passwordResetToken = v4();
}

function _setResetPasswordTokenSentAt(userDto) {
  userDto.passwordResetTokenSentAt = new Date().toISOString();
}

function _clearOneTimeToken(userDto, jwToken) {
  let claims = jwtDecode(jwToken);

  if (claims.activationToken) {
    userDto.activationToken = null;
  } else if (claims.passwordResetToken) {
    userDto.passwordResetToken = null;
    userDto.passwordResetTokenSentAt = null;
  }
}

function _createClaims(claims, userDto) {
  let iat = Math.floor(Date.now() / 1000);

  return {
    ...claims,
    iat,
    exp: iat + 3600,
    userId: userDto.id,
    roleIds: userDto.roleIds,
    activatedAt: userDto.activatedAt,
  };
}

class AuthCache {
  constructor(storage) {
    this.storage = storage;
  }

  set(auth) {
    this.storage.setItem('cachedAuth', JSON.stringify(auth));
  }

  get() {
    let auth = this.storage.getItem('cachedAuth');
    return auth && JSON.parse(auth);
  }

  remove() {
    this.storage.removeItem('cachedAuth');
  }
}
