import faker from 'faker';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class GuidePerformanceService {
  constructor(getDatabase) {
    this.getDatabase = getDatabase;
  }

  findAnswerPerformance(attrs, callback, path, { orgId }, query) {
    let answerPerformanceCount;

    switch (query['f.t']) {
      case '1d':
        answerPerformanceCount = 75;
        break;
      case '7d':
        answerPerformanceCount = 185;
        break;
      case '30d':
        answerPerformanceCount = 230;
        break;
      default:
        // '90d'
        answerPerformanceCount = 356;
    }

    // If the audience fiter is set, reduce the number of answer performance details
    if (query['f.aid']) {
      answerPerformanceCount = Math.floor(answerPerformanceCount / faker.random.number({ min: 2, max: 6 }));
    }

    const answerPerformanceDetails = [];

    for (let i = 0; i < answerPerformanceCount; i++) {
      const references = faker.random.number({ min: 1, max: 500 });
      const handOffs = faker.random.number({ min: 0, max: references });
      const resolved = references - handOffs;
      answerPerformanceDetails.push({
        id: `answer-performance-detail-${i}`,
        name: faker.company.catchPhrase(),
        references,
        handOffs,
        resolved,
        resolutionRate: parseFloat(((resolved / references) * 100).toFixed(2)),
      });
    }

    // If the audience filter is not set, add a "No answer referenced" entry
    if (!query['f.aid']) {
      const references = faker.random.number({ min: 1, max: 500 });
      answerPerformanceDetails.push({
        id: `no_answer_referenced_id`,
        name: 'No answer referenced',
        references,
        handOffs: references,
        resolved: 0,
        resolutionRate: 0,
      });
    }

    callback(null, { status: 200, statusText: statusText(200), response: answerPerformanceDetails });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/answer-performance': {
          GET: this.findAnswerPerformance,
        },
      },
      this
    );
  }
}
